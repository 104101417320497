import * as React from 'react';
var styles = require('./side-bar.scss');
var SideBar = function (props) {
    return (React.createElement("aside", null,
        React.createElement("span", { className: styles.currentVin },
            "VIN: ",
            props.vin),
        React.createElement("p", null, "Mazda has a reimbursement program in place for repairs performed on your vehicle that were related to a recall, service campaign or customer service program (collectively referred to as \u201CCampaign\u201D) where you paid out of pocket for the expense prior to the launch of the Campaign. To file a claim, please enter your VIN on the right and follow the screen prompts to choose the Campaign available for your vehicle, and fill out the form and attach the required documentation.  Mazda also has paperless transactions, and all payments will be in the form of a Reward Card provided by JNR, Inc. on behalf of Mazda.  The Reward Card can be used to make in store, online, mail, and phone order purchases, as well as ATM withdrawals."),
        React.createElement("p", null,
            React.createElement("span", { className: styles.title }, "Reimbursement Claim Form"),
            React.createElement("br", null),
            "In order to process your claim completely, please have the following information.  The individual who originally paid for the repairs must be the person filling out the application.",
            React.createElement("ul", null,
                React.createElement("li", null, "Your current name, address and phone number."),
                React.createElement("li", null, "A current, valid email address"),
                React.createElement("li", null,
                    "Paid repair order or invoice receipt showing:",
                    React.createElement("ul", null,
                        React.createElement("li", null, "Vehicle model and year, and vehicle identification number (VIN)"),
                        React.createElement("li", null, "Your name and address at the time of repair"),
                        React.createElement("li", null, "Description of the recall defect, noncompliance or Campaign concern reported as described in the reimbursement documentation you received"),
                        React.createElement("li", null, "Total amount paid for repair of the concern"),
                        React.createElement("li", null, "Proof of payment for repairs completed (cash or credit card receipt, or cancelled check copy)")))),
            "Note: Additional information may be required if the repair order or invoice receipt are unclear about whether the repair addresses the Campaign. For a replacement vehicle part, the receipt must identify the item and the total amount paid.")));
};
export default SideBar;
